import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {addHours, addMinutes, format} from "date-fns";
import {BookingBase, Place} from "../assets/interface/interface";
import axios from "axios";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";
interface Props {
    book: BookingBase;
    struttura: Place;
    date?: string;
}
const InfoSportelloDetail = (props: Props) => {
    const [slot, setSlot] = useState<number>();
    const {showToast} = useContext(ToastContext)!;

    async function getLaboratorio() {

        if (!props.book.sportello) {
            return;
        }

        try {
            const response = await axios.get(`${config.apiUrl}/front/get_laboratorio.php`, {
                params: {
                    id: props.book.sportello
                }
            });

            setSlot(parseInt(response.data[0].slot));

        } catch (error) {
            // Gestisci eventuali errori qui
            showToast("danger", "Errore", "Errore durante la richiesta del laboratorio.")
            console.error('Errore durante la richiesta API:', error);
        }
    }

    useEffect(() => {
        getLaboratorio();
    }, [])

    return (
        <div className="mt-4 bg-secondary p-4">
            <div className="d-flex flex-row gap-3 align-items-center">
                <div className="fs-3 text-primary">
                    <FontAwesomeIcon icon={faLocationDot}/>
                </div>
                <div>
                    <div className="fw-bold text-uppercase text-black">
                        {props.struttura?.descrizione}
                    </div>

                    <div>
                        {props.struttura?.indirizzo && props.struttura?.citta && (
                            <span>{props.struttura?.indirizzo}, {props.struttura?.citta}</span>)}
                    </div>
                </div>
            </div>
            {props.date && <div className="d-flex flex-row gap-3 align-items-center my-2">
                <div className="fs-3 text-primary">
                    <FontAwesomeIcon icon={faCalendar}/>
                </div>
                <div className="fw-bold text-uppercase text-black">
                    {props.date && format(new Date(props.date), 'dd/MM/yyyy')}
                </div>
            </div>}

            {props.date && <div className="d-flex flex-row gap-3 align-items-center">
                <div className="fs-3 text-primary">
                    <FontAwesomeIcon icon={faClock}/>
                </div>

                <div className="fw-bold text-uppercase text-black">
                    {props.date && new Date(props.date).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    })}
                    {!props.struttura?.citta && <>
                        - {props.date && addMinutes(new Date(props.date), (slot ? slot : 60)).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}
                    </>}

                </div>
            </div>}
        </div>
    );
};

export default InfoSportelloDetail;
