export function convertStringToNumberArray(input: string): any {
    if (input) {
        const cleanInput = input.replace(/\s/g, '');
        const stringArray = cleanInput.split(',');
        const numberArray = stringArray.map((numStr) => Number(numStr));
        return numberArray.filter((num) => !isNaN(num));
    }
}

export function formatMinutesToTime(totalMinutes: number){
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = 0; // Assume 00 seconds
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

interface JsonObject {
    [key: string]: any;
}

export function cleanJSON(jsonData: JsonObject | JsonObject[]): any {
    if (Array.isArray(jsonData)) {
        return jsonData.map((item) => cleanJSON(item));
    } else if (typeof jsonData === 'object' && jsonData !== null) {
        if ('label' in jsonData && 'value' in jsonData && Object.keys(jsonData).length === 2) {
            return jsonData['value'];
        } else {
            for (const key in jsonData) {
                jsonData[key] = cleanJSON(jsonData[key]);

                if (jsonData[key] instanceof Date) {
                    jsonData[key] =  jsonData[key].toISOString();
                }
            }
            return jsonData;
        }
    } else {
        return jsonData;
    }
}

function isBreakDays(date: Date, breakDay: number[]): boolean {
    const day = date.getDay();
    return breakDay.includes(day);
}

function addBusinessDays(date: Date, numDays: number, breakDay: number[]): Date {
    let count = 0;
    while (count < numDays) {
        date.setDate(date.getDate() + 1);
        if (!isBreakDays(date, breakDay)) {
            count++;
        }
    }
    return date;
}

export function getFollowingDays(breakDay: number[], followingWorkingDays: number): Date {
    const today = new Date();
    return addBusinessDays(today, followingWorkingDays, breakDay);
}

export function isValidCodiceFiscale(codiceFiscale: string): boolean {
    if (codiceFiscale.length !== 16) {
        return false;
    } else {
        codiceFiscale = codiceFiscale.toUpperCase();

        const monthLetter = /^[ABCDEHLMPRST]+$/;

        const cognome = codiceFiscale.substring(0, 3);
        const nome = codiceFiscale.substring(3, 6);
        const annoNascita = codiceFiscale.substring(6, 8);
        const meseNascita = codiceFiscale.charAt(8);
        const giornoNascita = codiceFiscale.substring(9, 11);
        const codiceComune = codiceFiscale.substring(11, 15);

        return !!(cognome.match(/^[A-Z]{3}$/) &&
            nome.match(/^[A-Z]{3}$/) &&
            annoNascita.match(/^\d{2}$/) &&
            meseNascita.match(monthLetter) &&
            giornoNascita.match(/^\d{2}$/) &&
            codiceComune.match(/^[0-9A-Z]{4}$/)
        );
    }

}

// Funzione per aggiungere minuti a una data
export const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
};

export  const extractStartTime = (timeRange: string) => {
    return timeRange.split(' - ')[0];
};


export const formatTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);  // Calcola le ore
    const minutes = totalMinutes % 60;            // Calcola i minuti rimanenti

    // Crea la stringa formattata
    return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}min` : ''}`;
};

// Funzione per formattare la data in yyyy-mm-dd
export function formatDate(date: any) {
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // Converte in formato yyyy-mm-dd
}

export function formatTimeRange(dateStartTime: Date, dateEndTime: Date) {

    return `${dateStartTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })} - ${dateEndTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}`;
}

export const formatDateTime = (date: any) => {

    if (!(date instanceof Date)) { // Corrected type check
        date = new Date(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const returnCoordinateAllevamento = async (indirizzo: string, comune: string) => {
    const indirizzoFull = indirizzo + " " + comune;
    const indirizzoEncoded = indirizzoFull.replace(/ /g, '+');

    const response = await fetch('https://nominatim.openstreetmap.org/search?q=' + indirizzoEncoded + '&format=json');
    const data = await response.json();

    if (data && data[0] && data[0].lat && data[0].lon) {
        const newCoordinate = `${data[0].lat},${data[0].lon}`;
        return {
            id: "1",
            coordinate: newCoordinate,
        };
    }
}
