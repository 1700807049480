import Form from 'react-bootstrap/Form';
import {FormGroup} from "react-bootstrap";
import Select from 'react-select'
import {Option} from "../assets/interface/interface";
import {FormikProps} from "formik";

interface Props {
    name: string;
    formik: FormikProps<any>;
    label: string;
    value: Option;
    placeholder?: string;
    options: Option[];
    disabled?: boolean;
    note?: string;
    isMulti?: boolean;
    onChange?: (newValue: any) => void;
    bold?: boolean;
    error?: string;
}

const customStyles = {
    control: (provided: any, state:any) => ({
        ...provided,
        borderColor: state.isFocused ? '#01762F' : provided.borderColor, // Colore del bordo
        boxShadow: state.isFocused ? `0 0 0 2px #01762F` : provided.boxShadow, // Ombra quando selezionato
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#f2f8f4' : provided.backgroundColor,
        color: state.isFocused ? '#01762F' : provided.color,
        '&:hover': {
            backgroundColor: '#f2f8f4',
            color: '#01762F',
        },
        zIndex: 100,
        ...(state.isSelected && { backgroundColor: '#01762F', color: 'white' }), // Aggiungi questa regola per l'opzione attiva selezionata
    }),

};
export default function FormSelect(props: Props ) {
    const { isMulti, value, formik, name, options, placeholder, disabled, label, note, onChange, bold, error } = props;

    const onChangeSelected = (change: any) => {
        formik.setFieldValue(name, change);
    }

    return (
        <FormGroup>
            <Form.Label className={`label ${bold ? 'fw-bold text-black' : ''}`}>{label}</Form.Label>
            <Select
                styles={customStyles}
                options={options.map(option => ({ value: option.id, label: option.descrizione }))}
                name={name}
                placeholder={placeholder ?? '-'} // Usa nullish coalescing per definire il placeholder di default
                onChange={onChange ? onChange : onChangeSelected}
                value={value}
                isMulti={isMulti}
                isDisabled={disabled}
            />
            {props.error && <Form.Text className="mt-2" style={{color: "#FF0000"}}>
                {props.error}
            </Form.Text>}
            {note ? <Form.Text muted>{note}</Form.Text> : null}
        </FormGroup>
    );
}
