import React from "react";
import {User} from "../assets/interface/interface";

interface Props {
    user: User;
}
const AvatarUser = (props: Props) => {

    const imageStyle = {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#E9EFEB",
        color: "#01762F"
    };

    return (
        <div className="d-flex felx-row gap-2 align-items-center">
            <div style={imageStyle} className="text-uppercase">
                {props.user.name?.charAt(0) + props.user.surname?.charAt(0)}
            </div>
            <div className="text-black">{props.user.name + " " + props.user.surname}</div>
        </div>
    );
};

export default AvatarUser;
