import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes } from "react-router-dom";
import BookingDetails from "./pages/BookingDetails";
import CreateBooking from "./pages/CreateBooking";
import BookingRequest from "./pages/BookingRequest";
import Contacts from "./pages/Contacts";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RelatedNotes from "./pages/RelatedNotes";
import FarmAnimalActivities from "./pages/FarmAnimalActivities";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<CreateBooking />} />
              <Route path="/booking/:id/:animal_type" element={<BookingDetails />} />
              <Route path="/booking-request/:id/:animal_type" element={<BookingRequest />} />
              <Route path="/contatti" element={<Contacts />} />
              <Route path="/note-legali" element={<RelatedNotes />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/animali-da-reddito" element={<FarmAnimalActivities />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
