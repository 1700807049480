import React, {useEffect} from "react";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import FormText from "./FormText";
import FormUpload from "./FormUpload";
import {formAnimaliPerPrenotazione, formMail, formNotes, formTelefono} from "../assets/utilities/variables";

interface Props {
    book: any;
    formik: any;
    tipologia: string;
    tipologia_animali: string;
    goToBackTab(): void;
}

const OwnershipData = (props: Props) => {

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const cancel = () => {
        props.goToBackTab();
    };

    return (
        <Formik initialValues={props.formik.initialValues}  onSubmit={props.formik.handleSubmit}>

            <Form onSubmit={props.formik.handleSubmit}>
                <div className="p-4 rounded-lg mb-100">
                    <h2 className="my-3">
                        Dati Personali
                    </h2>

                    {props.tipologia_animali === "animali_da_affezione" && <>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                <FormText name="nome" value={props.formik.nome} onChange={props.formik.handleChange}
                                          error={props.formik.errors["nome"]}
                                          type="text" label="Nome" placeholder="Inserisci qui"></FormText>

                            </div>
                            <div className="col-md-6 my-2">
                                <FormText name="cognome" value={props.formik.cognome}
                                          onChange={props.formik.handleChange} error={props.formik.errors["cognome"]}
                                          type="text" label="Cognome" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                <FormText name="codicefiscale" value={props.formik.codicefiscale}
                                          onChange={props.formik.handleChange}
                                          error={props.formik.errors["codicefiscale"]}
                                          type="text" label="Codice Fiscale" placeholder="Inserisci qui"></FormText>
                            </div>
                            <div className="col-md-6 my-2">
                                <FormText name="indirizzo" value={props.formik.indirizzo}
                                          onChange={props.formik.handleChange} error={props.formik.errors["indirizzo"]}
                                          type="text" label="Indirizzo" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                <FormText name="citta" value={props.formik.citta} onChange={props.formik.handleChange}
                                          error={props.formik.errors["citta"]}
                                          type="text" label="Città" placeholder="Inserisci qui"></FormText>
                            </div>
                            <div className="col-md-6 my-2">
                                <FormText name="cap" value={props.formik.cap} onChange={props.formik.handleChange}
                                          error={props.formik.errors["cap"]}
                                          type="number" label="CAP" placeholder="Inserisci qui"></FormText>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                <FormText name="provincia" value={props.formik.provincia}
                                          onChange={props.formik.handleChange} error={props.formik.errors["provincia"]}
                                          type="text" label="Provincia" placeholder="Inserisci qui"></FormText>
                            </div>
                            <div className="col-md-6 my-2">
                                {formTelefono(props.formik)}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                {formMail(props.formik)}
                            </div>
                            <div className="col-md-6 my-2">
                                <FormUpload formik={props.formik} name="documento_carta_identita"
                                            value={props.formik.values.documento_carta_identita}
                                            error={props.formik.errors["documento_carta_identita"]}
                                            type="file" label="Documento Carta di identità (1)"></FormUpload>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                <FormUpload formik={props.formik} name="documento_codicefiscale"
                                            value={props.formik.values.documento_codicefiscale}
                                            error={props.formik.errors["documento_codicefiscale"]}
                                            type="file" label="Documento Codice Fiscale (1)"></FormUpload>
                            </div>

                            <div className="col-md-6 my-2">
                                {formAnimaliPerPrenotazione(props.formik, props.tipologia)}
                            </div>

                        </div>

                    </>}

                    {props.tipologia_animali === "animali_da_reddito" && <>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                {formMail(props.formik)}
                            </div>
                            <div className="col-md-6 my-2">
                                {formTelefono(props.formik)}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 my-2">
                                {formAnimaliPerPrenotazione(props.formik, props.tipologia)}
                            </div>
                        </div>

                    </>}

                    <div style={containerActionsStyles}>

                        <i className="fs-14">Tutti i campi sono obbligatori</i>

                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary"
                                        onClick={cancel}>Indietro</Button>
                            </div>
                            <div>
                                <Button variant="primary" type="submit"
                                        disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default OwnershipData;
