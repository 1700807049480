import React, {useEffect, useRef, useState} from "react";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {convertStringToNumberArray} from "../assets/utilities/utilities";
import {Place} from "../assets/interface/interface";
import L, {Map as LeafletMap} from "leaflet";
interface Props {
    place: Place;
}
const MapSportello = (props: Props) => {
    const mapRiepilogo = useRef<LeafletMap | null>(null);
    const [showMap, setShowMap] = useState(false);

    const customIcon = new L.Icon({
        iconUrl: "/images/maps-and-flags.png",
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
    });

    useEffect(() => {
        console.log('props.place', props.place);
        setTimeout(() => {
            setShowMap(true);
        },100);
    }, []);

    return (
        <div style={{ width: '100%', height: '300px' }}>

            {showMap &&  <MapContainer ref={mapRiepilogo} center={convertStringToNumberArray(props.place?.coordinate)} zoom={15} style={{ width: "100%", height: '100%', zIndex: 0 }}>
                {mapRiepilogo && <>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                    <Marker
                        key={props.place?.id}
                        position={convertStringToNumberArray(props.place?.coordinate)}
                        icon={customIcon}
                    >
                    </Marker>
                </>}
            </MapContainer>}


        </div>
    );
};

export default MapSportello;
