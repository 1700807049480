import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './services/apiSlice'; // Assicurati di importare il tuo apiSlice

// Configura il tuo store
export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
