import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {Book} from "../assets/interface/interface";
import InfoSportello from "./InfoSportello";
import MapSportello from "./MapSportello";
import {useGetComuniQuery, useGetStruttureQuery} from "../services/apiSlice";
import {returnCoordinateAllevamento} from "../assets/utilities/utilities";

interface Props {
    goToBackTab(): void;
    modalShow(): void;
    book: Book;
    isSummary: boolean;
}
const Summary = (props: Props) => {

    let [place, setPlace] = useState<any>();
    
    useEffect(() => {
        if (props.book.tipologia_animali == "animali_da_reddito" && props.book.indirizzo_azienda && props.book.comune_azienda) {
            setPlaceCoordinate();
        }
    }, [props.book.azienda]);

    const setPlaceCoordinate = async () => {
        const coordinate = await returnCoordinateAllevamento(props.book.indirizzo_azienda, props.book.comune_azienda);
        const place = {
            ...coordinate,
            descrizione: props.book.ragione_azienda,
            indirizzo: props.book.indirizzo_azienda,
            citta: props.book.comune_azienda,
            telefono_1: "",
            telefono_2: ""
        }
        setPlace(place);
    }


    const { data: comuniData, error: errorComuni, isLoading } = useGetComuniQuery(null);
    const { data: struttureData, error: errorStrutture,  } = useGetStruttureQuery();

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    function setShowModal() {
        props.modalShow()
    }

    return (
        <div className="p-4 rounded-lg mb-100">

            <h2 className="my-3">
                Riepilogo
            </h2>

            <div className="wrapper gap-4 pt-2">
                <div className="gap-4 pt-2">
                    <div>
                        {props.isSummary && (
                            props.book.tipologia_animali === "animali_da_affezione" ? (
                                struttureData && props.book.sportello.value ? (
                                    <MapSportello
                                        place={struttureData.filter(str => str.id === props.book.sportello.value)[0]}
                                    />
                                ) : null
                            ) : (
                                place ? (
                                    <MapSportello
                                        place={place}
                                    />
                                ) : null
                            )
                        )}

                    </div>
                </div>
                <div className="bg-secondary p-4 border-1 border-primary" style={{borderStyle: 'solid'}}>
                    <InfoSportello book={props.book} ></InfoSportello>
                </div>
            </div>

            <div style={containerActionsStyles}>
                <hr/>
                <div style={actionsStyles}>
                    <div>
                        <Button className="bg-white" variant="outline-primary" onClick={props.goToBackTab}>Indietro</Button>
                    </div>
                    <div>
                        <Button variant="primary" onClick={setShowModal}>Prossimo</Button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Summary;
